
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin } from '@/mixins';

import BestAthletesLogo from '../ui/BestAthletesLogo.vue';
import SideNavSectionTitle from './SideNavSectionTitle.vue';
import { TeamModel } from '@/models/team';

@Component({
	components: {
		BestAthletesLogo,
		SideNavSectionTitle,
	},
})
export default class PublicCoachSideNav extends Mixins(VuetifyMixin, BAIconsMixin){
	@Prop({ default: true, type: Boolean }) value: boolean;

	mini = false;

	get PublicTeams(): Partial<TeamModel>[] {
		return [
			{
				id: '0001',
				name: 'Best Team',
				logoUrl: 'https://cdn.bestathletes.co/team-logos/daf599e1-ba24-4987-aee1-db20a999fb11.png',
			},
			// {
			// 	id: '0002',
			// 	name: 'Canada U17',
			// 	logoUrl: 'https://upload.wikimedia.org/wikipedia/en/6/69/Canadian_Soccer_Association_logo.svg',
			// }
		]
	}

	gotoLogin() {
		this.$router.push('/');
	}

}
